import { gql, useLazyQuery } from "@apollo/client";
import {
  GetZoomClientConfigurationForEngagementQuery,
  GetZoomClientConfigurationForEngagementQueryVariables,
  ZoomClientConfiguration,
} from "@generated/graphql";
import { LandingPageContainer } from "components/shared/LandingPageContainer/LandingPageContainer";
import { useStudentData } from "contexts/StudentDataProvider";
import { useEffect, useState } from "react";
import { StudentZoomClientPage } from "sections/ZoomClient/StudentZoomClientPage/StudentZoomClientPage";
import { defaultZoomConfig } from "sections/ZoomClient/constants";
import { StudentLandingPageBody } from "./components/StudentLandingPageBody";

const GET_ZOOM_CONFIG = gql`
  query GetZoomClientConfigurationForEngagement($engagementId: ID!) {
    getZoomClientConfigurationForEngagement(engagementId: $engagementId) {
      id
      name
      description
      showGoogleSearch
      videoClientLinkIds
      initialZoomWidthPercent
      linkedEngagements {
        id
        name
        zoomClientConfigurationId
      }
      videoClientLinks {
        id
        name
        url
        forceExternal
        bgColor
        iconPath
        isActivelyUsed
      }
    }
  }
`;

export const StudentLandingPage = () => {
  const { showZoomClient, engagementId } = useStudentData();
  const [zoomConfig, setZoomConfig] =
    useState<ZoomClientConfiguration>(defaultZoomConfig);

  const [getZoomClient, { loading }] = useLazyQuery<
    GetZoomClientConfigurationForEngagementQuery,
    GetZoomClientConfigurationForEngagementQueryVariables
  >(GET_ZOOM_CONFIG);

  useEffect(() => {
    const fetchZoomConfig = async () => {
      if (engagementId && showZoomClient && !loading) {
        const { data } = await getZoomClient({ variables: { engagementId } });
        if (data && data.getZoomClientConfigurationForEngagement) {
          setZoomConfig(data.getZoomClientConfigurationForEngagement);
        }
      }
    };

    fetchZoomConfig();
  }, [engagementId, getZoomClient, loading, showZoomClient]);

  return showZoomClient ? (
    <StudentZoomClientPage zoomConfig={zoomConfig} />
  ) : (
    <LandingPageContainer showInfoBanner>
      <StudentLandingPageBody />
    </LandingPageContainer>
  );
};
