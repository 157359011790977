import { Button, ErrorBox } from "components/shared";
import { useStudentData } from "contexts/StudentDataProvider";
import { useState } from "react";
import { StudentForm } from "./StudentForm/StudentForm";
import { StudentMeeting } from "./StudentMeeting/StudentMeeting";

export const StudentLandingPageBody = () => {
  const { student, setStudent } = useStudentData();
  const [errorMsg, setErrorMsg] = useState<string>();
  const isGetAccessMode = !student && !errorMsg;

  const headerText = isGetAccessMode
    ? "Student Access"
    : errorMsg
    ? "Student Access Error"
    : "";

  return (
    <div className="flex flex-col justify-center w-full z-20 mb-0 sm:mb-14">
      <h1 className="text-xl font-semibold text-center text-slate-700 mb-6">
        {headerText}
      </h1>

      {isGetAccessMode && (
        <StudentForm
          onError={(msg) => setErrorMsg(msg)}
          onSuccess={(student) => setStudent(student)}
        />
      )}

      {student && <StudentMeeting />}

      <ErrorBox className="mb-4" msg={errorMsg} />

      {errorMsg && (
        <Button
          className="mx-auto w-fit"
          onClick={() => setErrorMsg(undefined)}
        >
          Try again
        </Button>
      )}
    </div>
  );
};
