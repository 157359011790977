import clsx from "clsx";
import { TbTLogo } from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import { useRef } from "react";
import {
  bottomLeftLeaf,
  bottomMessageBox,
  gradHat,
  laptopImage,
  leaningBooks,
  rocket,
} from "./helpers";

type Props = {
  className?: string;
  showInfoBanner?: boolean;
  children: React.ReactNode;
};

export const LandingPageContainer = ({
  children,
  className,
  showInfoBanner = false,
}: Props) => {
  const { screenHeight, screenWidth, isLessThanMdScreen } = useLayout();
  const containerRef = useRef<HTMLDivElement>(null);

  let laptopWidth = screenWidth * 0.75;
  if (laptopWidth > 1050) laptopWidth = 1050;
  if (screenWidth < 1050) laptopWidth = screenWidth;
  const laptopHeight = laptopWidth * 0.6;
  const paddingTop = Math.max(20, (screenHeight - 550) / 2 - laptopHeight / 2);

  return (
    <div
      ref={containerRef}
      className="flex flex-col justify-start items-center relative h-screen w-screen overflow-hidden bg-slate-200 z-0"
    >
      <div
        className={clsx(
          "w-full flex flex-col h-auto justify-starts items-center gap-y-[35px] z-20"
        )}
        style={{ paddingTop: `${isLessThanMdScreen ? 60 : paddingTop}px` }}
      >
        <TbTLogo width={150} />
        <div className="relative w-auto z-100">
          <div className="invisible md:visible z-90">
            {laptopImage(laptopWidth, laptopHeight)}
          </div>
          <div
            className={clsx(
              "absolute flex flex-col items-center justify-between overflow-visible ",
              "md:overflow-hidden md:bg-slate-50 rounded-lg md:mt-0 p-6"
            )}
            style={{
              top: `${laptopHeight * 0.06}px`,
              left: `${laptopWidth * 0.09}px`,
              width: `${laptopWidth * 0.82}px`,
              height: `${laptopHeight * 0.81}px`,
            }}
          >
            <div
              className={clsx(
                "flex flex-col justify-center md:p-0 bg-slate-50 md:bg-none",
                "p-6 w-[90vw] mx-10 md:m-0 md:w-full h-auto md:h-full",
                "rounded-md mt-[-25px] md:mt-0 z-20",
                className
              )}
            >
              {children}
            </div>
          </div>
        </div>

        <div className="hidden md:block">
          {gradHat}
          {rocket}
        </div>
        {leaningBooks}
        {bottomLeftLeaf}
      </div>
      {showInfoBanner && bottomMessageBox}
    </div>
  );
};
