import { gql } from "@apollo/client";
import { StudentFormFaq_StudentLinkCustomizationsFragment } from "@generated/graphql";
import { ButtonLink } from "components/shared";

StudentFormFAQ.fragments = {
  studentLinkCustomizations: gql`
    fragment StudentFormFAQ_StudentLinkCustomizations on StudentLinkCustomizations {
      url1
      url2
      url3
      title
      helpText
      anchorText1
      anchorText2
      anchorText3
    }
  `,
};

type Props = {
  customizations:
    | StudentFormFaq_StudentLinkCustomizationsFragment
    | null
    | undefined;
};

export function StudentFormFAQ({ customizations }: Props) {
  if (!customizations) return null;

  const { url1, url2, anchorText3 } = customizations;
  const { anchorText1, anchorText2, url3 } = customizations;

  const links = [
    { url: url1, anchor: anchorText1 },
    { url: url2, anchor: anchorText2 },
    { url: url3, anchor: anchorText3 },
  ].filter((item) => Boolean(item.url));

  return customizations ? (
    <div className="flex w-full flex-col items-center">
      <div className="flex flex-col items-center w-fit max-w-full text-sm gap-y-2">
        <p>{customizations.helpText}</p>
        <div className="flex flex-wrap relative h-fit w-full gap-2">
          {links.map(({ url, anchor }, i) => (
            <ButtonLink
              key={i}
              height="sm"
              theme="tertiary"
              openInNewTab
              href={url ?? ""}
            >
              {anchor || url}
            </ButtonLink>
          ))}
        </div>
      </div>
    </div>
  ) : null;
}
