import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth } from "@contexts/AuthProvider";
import {
  AccessLogStatus,
  AddSchoologyAccessLogMutation,
  AddSchoologyAccessLogMutationVariables,
  StudentLandingPageGetPublicOrganizationQuery,
  StudentLandingPageGetPublicOrganizationQueryVariables,
} from "@generated/graphql";
import { Banner, ButtonLink, Spinner } from "@shared";
import { LandingPageContainer } from "@shared/LandingPageContainer";
import { fetchErrToast } from "@utils/errorLogging";
import { getIFrameLocation, isInIframe } from "@utils/iFrame";
import {
  parseOrganizationShortcut,
  parseQueryExternalStudentId,
} from "@utils/parsing";
import { Routes } from "@utils/routes";
import { StudentDataProvider } from "contexts/StudentDataProvider";
import type { GetServerSidePropsContext, NextPage } from "next";
import { useEffect, useState } from "react";
import { StudentLandingPage } from "sections/StudentLandingPage/StudentLandingPage";

export const SCHOOLOGY_URL = "https://app.schoology.com";

const STUDENT_LANDING_PAGE_GET_PUBLIC_ORGANIZATION = gql`
  query StudentLandingPageGetPublicOrganization(
    $organizationShortcut: String!
  ) {
    publicOrganization(shortcut: $organizationShortcut) {
      id
      name
      shortcut
    }
  }
`;

const ADD_SCHOOLOGY_ACCESS_LOG = gql`
  mutation AddSchoologyAccessLog($input: AccessLogCreateInput!) {
    logRouteAccess(input: $input)
  }
`;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { organizationShortcut } = parseOrganizationShortcut(context.params);

  const externalStudentIdParam = context.query.externalStudentId
    ? parseQueryExternalStudentId(context.query).externalStudentId
    : null;

  if (organizationShortcut.match(/[^a-z0-9-]/)) {
    return {
      redirect: {
        permanent: false,
        destination: Routes.notFound.href(),
      },
    };
  }

  return { props: { organizationShortcut, externalStudentIdParam } };
}

type Props = {
  externalStudentIdParam?: string;
  organizationShortcut: NonNullable<string>;
};

const Page: NextPage<Props> = ({
  organizationShortcut,
  externalStudentIdParam,
}) => {
  const { setActiveRoute } = useAuth();
  const isLAUSDOrg = organizationShortcut === "lausd";
  const [preventLogin, setPreventLogin] = useState(false);

  const { data, loading, error } = useQuery<
    StudentLandingPageGetPublicOrganizationQuery,
    StudentLandingPageGetPublicOrganizationQueryVariables
  >(STUDENT_LANDING_PAGE_GET_PUBLIC_ORGANIZATION, {
    fetchPolicy: "network-only",
    variables: { organizationShortcut },
    onError: (error) => fetchErrToast("this organization.", error),
  });

  const [addAccessLog] = useMutation<
    AddSchoologyAccessLogMutation,
    AddSchoologyAccessLogMutationVariables
  >(ADD_SCHOOLOGY_ACCESS_LOG);

  useEffect(() => {
    if (isLAUSDOrg) {
      if (!isInIframe()) setPreventLogin(true);
      const { Allowed, Denied } = AccessLogStatus;
      const iFrameText = `${isInIframe() ? "" : "NOT"} from an iFrame`;
      const studentText = `LAUSD Student (${externalStudentIdParam}) is attempting to login`;

      addAccessLog({
        variables: {
          input: {
            url: getIFrameLocation(),
            route: "/[organizationShortcut]",
            status: isInIframe() ? Allowed : Denied,
            description: `${studentText}${iFrameText}`,
          },
        },
      });
    }
  }, [addAccessLog, externalStudentIdParam, isLAUSDOrg, organizationShortcut]);

  useEffect(() => {
    if (!loading && (error || !data?.publicOrganization?.id))
      setActiveRoute(Routes.notFound);
  }, [loading, error, data, setActiveRoute]);

  if (error || !data?.publicOrganization?.id) return null;

  return loading ? (
    <Spinner />
  ) : preventLogin ? (
    <LandingPageContainer>
      <div className="flex flex-col justify-center w-full z-20 mb-0 sm:mb-14">
        <h1 className="text-2xl font-bold text-center text-slate-700 mb-6">
          Security Error
        </h1>

        <Banner
          type="error"
          className="mx-5"
          title="You must login to LAUSD via Schoology"
          actions={
            <ButtonLink openInNewTab href={SCHOOLOGY_URL}>
              Go to Schoology
            </ButtonLink>
          }
        />
      </div>
    </LandingPageContainer>
  ) : (
    <StudentDataProvider
      organizationId={data.publicOrganization.id}
      organizationShortcut={organizationShortcut}
      externalStudentIdParam={externalStudentIdParam}
    >
      <StudentLandingPage />
    </StudentDataProvider>
  );
};

export default Page;
