import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { NextSessionStudent } from "@contexts/StudentDataProvider/StudentDataProvider";
import {
  StudentGetNextSessionQuery,
  StudentLinkCustomizationsQuery,
  StudentLinkCustomizationsQueryVariables,
  VideoProvider,
} from "@generated/graphql";
import {
  openInNewTab,
  openInSameTab,
  sessionStorageUtils,
} from "@utils/browser";
import { Button, FieldError, Input, Spinner } from "components/shared";
import { useStudentData } from "contexts/StudentDataProvider";
import { useEffect, useState } from "react";
import { getStudentMeetingDetails } from "../StudentMeeting/helpers";
import { StudentFormFAQ } from "./components/StudentFormFAQ";

const STUDENT_ID_INPUT_KEY = "studentForm:studentId";

const STUDENT_FORM_GET_STUDENT_COHORTS_VIA_ORGANIZATION = gql`
  query StudentGetNextSession($input: StudentNextSessionInput!) {
    getStudentNextSession(input: $input) {
      id
      cohortId
      timeZone
      meetingId
      cohortName
      meetingRoom
      engagementId
      videoProvider
      cohortSubject
      durationMinutes
      cohortSubSubject
      externalStudentId
      startFloatingDateTime
      videoClientIntegration
      ...StudentMeetingDetails_StudentNextSession
    }
  }
  ${getStudentMeetingDetails.fragments.studentNextSession}
`;

const STUDENT_LINK_CUSTOMIZATIONS_QUERY = gql`
  query StudentLinkCustomizations($organizationId: ID!) {
    studentLinkCustomizations(organizationId: $organizationId) {
      ...StudentFormFAQ_StudentLinkCustomizations
      joinText
    }
  }
  ${StudentFormFAQ.fragments.studentLinkCustomizations}
`;

type Props = {
  onError: (msg: string) => void;
  onSuccess: (student: NextSessionStudent) => void;
};

export function StudentForm({ onError, onSuccess }: Props) {
  const [errorMsg, setErrorMsg] = useState<string>();
  const initId = sessionStorageUtils.getItem(STUDENT_ID_INPUT_KEY);
  const [externalIdInput, setExternalIdInput] = useState(initId ?? "");

  const {
    organizationId,
    organizationShortcut,
    externalStudentIdParam,
    setMeetingId,
    setEngagementId,
    setShowZoomClient,
  } = useStudentData();

  const handleExternalIdInput = (input: string) => {
    sessionStorageUtils.setItem(STUDENT_ID_INPUT_KEY, input);
    setExternalIdInput(input);
  };

  const { data: studentCustomizationData } = useQuery<
    StudentLinkCustomizationsQuery,
    StudentLinkCustomizationsQueryVariables
  >(STUDENT_LINK_CUSTOMIZATIONS_QUERY, { variables: { organizationId } });

  const [getStudentNextSession, { loading, called }] =
    useLazyQuery<StudentGetNextSessionQuery>(
      STUDENT_FORM_GET_STUDENT_COHORTS_VIA_ORGANIZATION,
      {
        fetchPolicy: "no-cache",
        onCompleted: ({ getStudentNextSession: data }) => {
          if (externalStudentIdParam) {
            if (data?.meetingRoom) openInSameTab(data.meetingRoom);

            onSuccess({
              nextSession: data ? data : null,
              studentExternalId: externalStudentIdParam,
            });
          }
        },
        onError: (error) => {
          setErrorMsg(error.message);
          onError(
            "An unknown error occurred. Please ask your teacher for help!"
          );
        },
      }
    );

  // Auto-login via externalStudentId Param
  useEffect(() => {
    if (externalStudentIdParam)
      getStudentNextSession({
        variables: {
          input: {
            organizationId,
            studentExternalId: externalStudentIdParam,
          },
        },
      });
  }, [
    organizationId,
    organizationShortcut,
    externalStudentIdParam,
    getStudentNextSession,
  ]);

  // Classic login via externalStudentId Input
  const joinNextMeeting = async () => {
    if (externalStudentIdParam || called) return;

    try {
      const { data } = await getStudentNextSession({
        variables: {
          input: { organizationId, studentExternalId: externalIdInput.trim() },
        },
      });

      if (!data || !data.getStudentNextSession) {
        onError(
          "We weren't able to find your student ID. Are you sure you typed it in correctly?"
        );
        return;
      }

      if (data?.getStudentNextSession?.meetingRoom) {
        const {
          meetingId,
          meetingRoom,
          engagementId,
          videoProvider,
          videoClientIntegration,
        } = data.getStudentNextSession;

        const isZoomProvider = videoProvider === VideoProvider.Zoom;

        if (videoClientIntegration && isZoomProvider && meetingId) {
          setMeetingId(meetingId);
          setShowZoomClient(true);
          setEngagementId(engagementId);
        } else {
          openInNewTab(meetingRoom);
        }
      }

      onSuccess({
        nextSession: data?.getStudentNextSession,
        studentExternalId: externalIdInput.trim(),
      });
    } catch (error) {
      console.error(error);
      onError("An unknown error occurred. Please ask your teacher for help!");
    }
  };

  const customizations = studentCustomizationData?.studentLinkCustomizations;
  const joinText = customizations
    ? customizations.joinText
    : "Please enter your student ID";

  return (
    <div className="flex flex-col relative w-full h-full flex-center overflow-y-auto gap-4">
      <div className="flex flex-col gap-y-px w-full">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            joinNextMeeting();
          }}
          className="flex items-end justify-center w-full gap-x-2"
        >
          <div className="flex flex-col grow max-w-[400px]">
            <Input
              id="student-id"
              label={joinText}
              value={externalIdInput}
              onChange={(e) => handleExternalIdInput(e.target.value)}
            />
          </div>

          <Button type="submit" className={"w-20"}>
            {loading ? <Spinner /> : "Join"}
          </Button>
        </form>
      </div>

      <FieldError className="flex items-center h-8" msg={errorMsg} />
      <StudentFormFAQ customizations={customizations} />
    </div>
  );
}
