import clsx from "clsx";
import Image from "next/legacy/image";

export const laptopImage = (w: number, h: number) => (
  <Image
    className="rounded-lg object-cover object-center shadow-lg z-0"
    src="/images/Laptop.svg"
    alt="Laptop"
    width={w}
    height={h}
  />
);

export const gradHat = (
  <div className="absolute top-[65px] right-[75px] z-0">
    <Image
      className="object-cover object-center"
      src="/images/grad-hat.svg"
      alt="graduation hat"
      width={149 * 0.6}
      height={182 * 0.6}
    />
  </div>
);

export const rocket = (
  <div className="absolute top-[50px] left-[-100px] z-0">
    <Image
      className="object-cover object-center"
      src="/images/Rocket.svg"
      alt="rocket"
      width={302}
      height={213}
    />
  </div>
);

export const leaningBooks = (
  <div className="absolute bottom-[-30px] right-[-55px] z-10">
    <Image
      className="object-cover object-center"
      src="/images/leaning-books.svg"
      alt="leaning books"
      width={436}
      height={364}
    />
  </div>
);

export const bottomLeftLeaf = (
  <div className="absolute bottom-[-10px] left-[-120px] rotate-[-20deg] z-11">
    <Image
      className="object-cover object-center"
      src="/images/Leafs.svg"
      alt="leaves"
      width={480}
      height={504}
    />
  </div>
);

export const bottomMessageBox = (
  <div className="relative md:absolute bg-pink-100/20 mt-[50px] mx-auto md:bottom-[90px] left-0 flex flex-center w-screen z-0">
    <div
      className={clsx(
        "flex z-50 flex-col justify-center w-[500px] max-w-[80vw] h-auto items-start p-6 bg-slate-700/80 rounded-md"
      )}
    >
      <div className="flex justify-center gap-x-2 flex-wrap text-center w-full">
        <h1 className="text-xl text-gold font-extrabold font-source-serif-pro">
          Online Tutoring.
        </h1>
        <h1 className="text-xl text-gold font-extrabold font-source-serif-pro">
          Powered by Teachers.
        </h1>
      </div>
      <p className="text-sm text-white mt-2 text-center px-6 leading-tight">
        We seek to improve student and teacher outcomes and advance equity in
        education through our Virtual Intervention Model.
      </p>
    </div>
  </div>
);
