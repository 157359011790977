export const isInIframe = (assumptionUponCatch = false) => {
  try {
    const isInIframe = window.self !== window.top;
    if (isInIframe) getIFrameLocation();

    return isInIframe;
  } catch (e) {
    return assumptionUponCatch; // Return assumption Upon Catch
  }
};

export const getIFrameLocation = (): string => {
  if (typeof window === "undefined") return "unknown";

  return window?.location !== window?.parent?.location
    ? document.referrer
    : "unknown";
};
