import { Card, CardDetailsRow } from "components/shared";
import { useStudentData } from "contexts/StudentDataProvider";
import { getStudentMeetingDetails } from "./helpers";

export const StudentMeeting = () => {
  const { student } = useStudentData();
  const { studentExternalId, nextSession } = student ?? {};
  const rows: CardDetailsRow[] = [["Student ID", studentExternalId]];

  const hasNextEvent = nextSession?.startFloatingDateTime;

  if (hasNextEvent) {
    rows.push([
      "Cohort",
      <span
        key={0}
      >{`${nextSession?.cohortName} (ID: ${nextSession?.cohortId})`}</span>,
    ]);
    rows.push(...getStudentMeetingDetails(nextSession));
  } else {
    rows.push([
      "Session",
      <span key={0} className="text-red-600">
        <p>No live tutoring sessions scheduled.</p>
      </span>,
    ]);
  }

  return (
    <div className="flex w-full h-full flex-center">
      <Card
        header="Student Session"
        icon="student"
        rows={rows}
        className="max-w-[500px]"
      />
    </div>
  );
};
